<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import Dropdown from '@/Components/Dropdown.vue';
const page = usePage();
const locale_language = computed(() => {
    if (page.props.locale && page.props.site_languages) {
        if (page.props.site_languages[page.props.locale]) {
          return page.props.site_languages[page.props.locale].name;
        }
    }
    return "Language";
});

defineProps({
    globe: {
        type: Boolean,
        required: false
    }
});
const dropdownAlign = computed(() => {
  return window.innerWidth <= 640 ? 'left' : 'right';
});

</script>

<template>
  <div>
    <Dropdown align='left' width="48" content-classes="bg-[#1A1A1A] py-1">
      <template #trigger>
        <span class="inline-flex rounded-md">
          <button
          id="language-selector"
            type="button"
            class="language-selector inline-flex items-center py-2 border border-transparent text-lg md:text-base rounded-md text-main-white focus:outline-none"
          >
            <!-- Conditional rendering for mobile layout -->
            <svg v-if="globe" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe md:mr-2" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
</svg>
           <span v-else> {{ locale_language }} </span>
            <svg v-if="!globe"
              class="ml-2 -mr-0.5 h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </span>
      </template>
      <template #content>
        <a
          class="block w-full px-4 py-2 text-left text-sm leading-5 text-main-white hover:bg-[#2C2C2C] focus:outline-none focus:bg-[#2C2C2C] transition duration-150 ease-in-out"
          v-for="language in $page.props.site_languages"
          :href="'/language-switch/' + language.code"
          :key="language.code"
          :dusk="'language-' + language.code"
        >
          {{ language.name }}
        </a>
      </template>
    </Dropdown>
  </div>
</template>
